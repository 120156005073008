import React from 'react'
import Features from '../components/features'
import Navbar from '../components/navbar'
import Footer from '../components/footer'

export default function error() {
  return (
    <div>
        <Navbar/>
        <Features/>
        <Footer/>
    </div>
  )
}
